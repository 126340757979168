<script setup lang="ts">
import icon from "~/assets/icon.svg"
import monitor from "~/assets/monitor.svg"
import api from "~/utils/api"
import { logger } from "@sentry/utils"

async function sendEmail() {
    try {
        await api.post("/send-mobile-to-desktop-email", {})
    } catch (error) {
        logger.error("Error on post-send-email", error)
        alert("Error while sending email. Please contact administrator")
        return
    }
}
</script>

<template>
    <div class="p-2 text-center flex flex-col mt-5">
        <div class="flex justify-center items-center">
            <img
                :src="icon"
                class="h-16 w-16 py-2 items-center"
            />
        </div>
        <div class="flex justify-center items-center mt-5">
            <img
                :src="monitor"
                class="h-56 w-56 py-2 max-sm:w-46 max-sm:h-46 items-center"
            />
        </div>
        <h1 class="text-[26px] max-sm:text-2xl mb-2 font-bold mt-5">
            Pitchlane is built for desktop
        </h1>
        <p class="text-left mb-1 px-10 max-sm:text-sm mt-5">
            For Best results log into Pitchlane on you computer.
        </p>
        <p class="text-left mb-1 px-10 max-sm:text-sm mt-3">
            Press the button below to send you an email with a link:
        </p>
        <div class="flex justify-center items-center mt-7 px-10">
            <PitchlaneButton
                event-name="User Details Confirmed"
                class="w-full text-center transition bg-[#202a62] max-sm:text-sm flex rounded-md items-center border-2 border-black justify-center font-light text-white p-2 disabled:opacity-80 disabled:cursor-not-allowed"
                @click="sendEmail()"
            >
                Send Login Link
            </PitchlaneButton>
        </div>
    </div>
</template>
