import { until } from "@vueuse/shared"
import { currentUser, currentUserData } from "./auth"
import trackEvent from "./track-event"

export default async function useLiveChat() {
    if (!window.Beacon) return

    window.Beacon("init", "ccc08988-a9c2-4bde-b8de-4ba55cca47b2")

    // analytics
    window.Beacon("on", "open", () => {
        trackEvent("HelpScout Beacon Opened")
    })
    window.Beacon("on", "close", () => {
        trackEvent("HelpScout Beacon Closed")
    })
    window.Beacon("on", "email-sent", (params) => {
        trackEvent("HelpScout Email Sent", params)
    })
    window.Beacon("on", "chat-started", (params) => {
        trackEvent("HelpScout Chat Started", params)
    })
    window.Beacon("on", "search", (params) => {
        trackEvent("HelpScout Articles Searched", params)
    })
    window.Beacon("on", "article-viewed", (params) => {
        trackEvent("HelpScout Article Viewed", params)
    })
    window.Beacon("on", "message-triggered", (params) => {
        trackEvent("HelpScout Message Triggered", params)
    })
    window.Beacon("on", "message-clicked", (params) => {
        trackEvent("HelpScout Message CTA Clicked", params)
    })
    window.Beacon("on", "message-closed", (params) => {
        trackEvent("HelpScout Message Closed", params)
    })

    // user identification
    await until(currentUser).toBeTruthy()
    const user = currentUser.value!

    let attributes: Record<string, any> = {
        name: user.name || user.email,
        email: user.email,
        user_id: user.sub,
        retool_link: `https://pitchlane.retool.com/apps/eb16b960-7b38-11ed-811d-43787b1402eb/Internal%20Portal/Internal%20Portal%20-%20Users%20View#user=${encodeURIComponent(
            user.sub!,
        )}`,
    }
    window.Beacon("identify", attributes)
    window.__ls?.("identify", attributes)

    // user subscription data
    await until(currentUserData).toBeTruthy()
    const { planName, monthlyVideos, hasActiveSubscription } =
        currentUserData.value?.subscription
    attributes = {
        ...attributes,
        planName,
        monthlyVideos,
        hasActiveSubscription,
    }
    window.Beacon("identify", attributes)

    // session replay
    window.__ls?.("getSessionURL", (url: string, isNew: boolean) => {
        attributes.session_replay = url
        window.Beacon?.("identify", attributes)
    })
}
