<script setup lang="ts">
import { useHead } from "@vueuse/head"
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue"
import useIsMobile from "~/use/useIsMobile"
import IconLinkedIn from "~icons/mdi/linkedin"

import { computed, provide, ref, watch } from "vue"
import { useRouter } from "vue-router"
import { quota } from "~/utils/quota"
import { currentUser, logout } from "~/utils/auth"
import faviconUrl from "~/assets/icon.svg"
// import videoCameraIcon from "~/assets/video-camera.svg"
import PitchlaneButton from "./components/PitchlaneButton.vue"
import { formatDistanceToNow } from "date-fns/formatDistanceToNow"

const isUserSignUpRoute = ref<boolean>(false)
const hideNavbar = ref<boolean>(false)
const freeTrialPopoverShow = computed(() => {
    return (
        !isUserSignUpRoute.value &&
        quota.loaded &&
        !quota.hasAnySubscription &&
        !quota.isInTrial &&
        !quota?.flags?.hideFreeTrialBanner
    )
})

const router = useRouter()
const mobileToDesktopModalOpen = ref(false)
const { isMobile } = useIsMobile()

const closewDialogBox = async () => {
    mobileToDesktopModalOpen.value = false
}

const unregisterServiceWorkers = async () => {
    if (!navigator.serviceWorker) {
        return
    }
    const registrations = await navigator.serviceWorker.getRegistrations()
    await Promise.all(
        registrations.map((registration) => registration.unregister()),
    )
}
unregisterServiceWorkers()

function checkIfUserUpdateRoute() {
    const userSignUpRoutes = [
        "onboarding-register",
        "onboarding-sending-tool",
        "onboarding-job-title",
        "onboarding-trial",
        "onboarding-referral-source",
        "onboarding-website",
    ]
    const hideNavbarRoutes = ["onboarding-register", "onboarding-trial"]
    const isSignupRoute = userSignUpRoutes.includes(
        router.currentRoute.value.name as string,
    )
    if (isSignupRoute) {
        isUserSignUpRoute.value = true
    } else {
        isUserSignUpRoute.value = false
        if (isMobile.value && router.currentRoute.value.name) {
            mobileToDesktopModalOpen.value = true
        } else {
            mobileToDesktopModalOpen.value = false
        }
    }

    hideNavbar.value = hideNavbarRoutes.includes(
        router.currentRoute.value.name as string,
    )
}

checkIfUserUpdateRoute()
watch([router.currentRoute, isMobile, quota], () => {
    checkIfUserUpdateRoute()
})

useHead({
    title: "Pitchlane",
    link: [{ rel: "icon", href: faviconUrl }],
})
const helpLink = "https://help.pitchlane.com"

const menuItems = [
    {
        title: "Pricing",
        url: "/org",
    },
    {
        title: "Domains",
        url: "/org/domains",
    },
    // {
    //     title: "Affiliate",
    //     url: "/affiliate",
    // },
]

provide("quota", quota)

// const videoModalOpen = ref(false)
</script>

<template>
    <!-- <BaseDialog
        v-model="videoModalOpen"
        :width="800"
    >
        <PlayVideoDialog
            video-src="https://www.veed.io/embed/352ef3c3-d948-4a4c-b4d2-d8fee6becb9e"
        />
    </BaseDialog> -->

    <BaseDialog
        v-model="mobileToDesktopModalOpen"
        persistent
        full-screen
    >
        <MobileToDesktopRedirectDialog
            :open="mobileToDesktopModalOpen"
            @close="closewDialogBox()"
        />
    </BaseDialog>
    <FreeTrialPopover :open="freeTrialPopoverShow" />

    <div class="h-screen flex flex-col">
        <!-- Nav/auth bar -->
        <div
            v-if="!hideNavbar"
            class="px-5 py-2 text-sm flex flex-row items-center justify-between border-b"
        >
            <div class="md:basis-1/6 xl:basis-1/3">
                <router-link
                    v-if="!isUserSignUpRoute"
                    to="/"
                    class="font-semibold"
                >
                    Pitchlane
                </router-link>
            </div>

            <div
                v-if="
                    quota.loaded &&
                        !isUserSignUpRoute &&
                        !quota.initialCreditsIssued &&
                        !quota.isInTrial &&
                        quota.videosLeft <= 0
                "
                class="hidden md:block text-center md:basis-2/6 xl:basis-1/3"
            >
                <div class="font-semibold text-indigo-900">
                    Verify your email (<span class="font-semibold">{{
                        currentUser?.email
                    }}</span>) to get free video credits.
                </div>
            </div>

            <div
                v-else-if="quota.loaded && !isUserSignUpRoute"
                class="hidden text-center md:basis-2/6 xl:basis-1/3 md:flex flex-col gap-2 items-center justify-center"
            >
                <div
                    v-if="!quota.flags?.hideCredits"
                    class="flex flex-row gap-5 items-center"
                >
                    <div
                        v-tooltip="
                            'This is the number of video credits you have. One video credit entitles you to two minutes worth of videos generated.'
                        "
                        class="flex flex-row items-center gap-1 py-3"
                    >
                        <heroicons-solid-video-camera class="text-indigo-900" />
                        <div>
                            <strong>{{ quota.videosLeft }}</strong> video
                            credits
                        </div>
                    </div>
                    <div
                        v-tooltip="
                            'LinkedIn credits are consumed for every LinkedIn profile you use in a Pitchlane video.'
                        "
                        class="flex flex-row items-center gap-1 py-3"
                    >
                        <IconLinkedIn class="text-indigo-900" />
                        <div>
                            <strong>{{
                                quota.linkedInPagesQuota.remaining
                            }}</strong>
                            LinkedIn credits
                        </div>
                    </div>
                    <router-link
                        v-if="
                            router.currentRoute.value.name !== 'org' &&
                                !quota.scheduledCancellation
                        "
                        class=""
                        to="/org"
                    >
                        <span class="text-blue-700 hover:text-blue-800">Need more?</span>
                    </router-link>
                </div>

                <span v-if="quota.renewal && quota.isInTrial">
                    Your free trial expires in
                    {{ formatDistanceToNow(quota.renewal) }}.
                </span>
            </div>

            <div class="md:basis-1/6 xl:basis-1/3">
                <div class="hidden md:flex justify-end items-center">
                    <!-- <PitchlaneButton
                        v-if="!isUserSignUpRoute"
                        class="flex items-center gap-1 px-2 py-2 text-sm text-gray-700 rounded hover:bg-gray-100 whitespace-nowrap"
                        type="button"
                        event-name="Watch This Button Pressed"
                        @click="videoModalOpen = true"
                    >
                        <img
                            :src="videoCameraIcon"
                            class="h-6 w-6"
                        />
                        <span>WATCH THIS</span>
                    </PitchlaneButton> -->
                    <template v-if="!isUserSignUpRoute">
                        <router-link
                            v-for="menuItem in menuItems"
                            :key="menuItem.url"
                            :to="menuItem.url"
                            class="block px-2 py-2 text-sm text-gray-700 rounded hover:bg-gray-100"
                        >
                            <span>{{ menuItem.title }}</span>
                        </router-link>
                    </template>
                    <a
                        v-if="!isUserSignUpRoute"
                        :href="helpLink"
                        class="block px-2 py-2 text-sm text-gray-700 rounded hover:bg-gray-100 whitespace-nowrap"
                        target="_blank"
                    >
                        <span>Help</span>
                    </a>
                    <Menu
                        as="div"
                        class="relative inline-block text-left"
                    >
                        <MenuButton
                            class="inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium"
                        >
                            <heroicons-user-circle class="h-6 w-6" />
                        </MenuButton>
                        <transition
                            class="z-[100]"
                            enter-active-class="transition ease-out duration-100"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95"
                        >
                            <MenuItems
                                class="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                                <div class="px-1 py-1">
                                    <MenuItem
                                        class="block px-2 py-2 text-sm text-gray-700 text-left rounded hover:bg-gray-100 whitespace-nowrap"
                                    >
                                        <router-link to="/profile">
                                            Profile
                                        </router-link>
                                    </MenuItem>
                                    <MenuItem
                                        class="block px-2 py-2 text-sm text-gray-700 text-left rounded hover:bg-gray-100 whitespace-nowrap"
                                    >
                                        <PitchlaneButton
                                            class="w-full"
                                            event-name="Log Out"
                                            @click="logout()"
                                        >
                                            Log out
                                        </PitchlaneButton>
                                    </MenuItem>
                                </div>
                            </MenuItems>
                        </transition>
                    </Menu>
                </div>
                <Menu
                    as="div"
                    class="relative md:hidden"
                >
                    <div>
                        <MenuButton
                            class="px-2 py-1 rounded hover:bg-gray-300 border-solid border-gray-200 bg-gray-200"
                        >
                            {{ currentUser?.name || "Menu" }}
                        </MenuButton>
                    </div>
                    <transition
                        class="z-[100]"
                        enter-active-class="transition ease-out duration-100"
                        enter-from-class="transform opacity-0 scale-95"
                        enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-from-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95"
                    >
                        <MenuItems
                            class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                            <!-- <MenuItem>
                                <PitchlaneButton
                                    class="flex items-center gap-1 px-4 py-2 text-sm text-gray-700 min-w-[155px]"
                                    event-name="Watch This Button Pressed"
                                    @click="videoModalOpen = true"
                                >
                                    <img
                                        :src="videoCameraIcon"
                                        class="h-6 w-6"
                                    />
                                    <span>WATCH THIS</span>
                                </PitchlaneButton>
                            </MenuItem> -->
                            <template v-if="!isUserSignUpRoute">
                                <MenuItem
                                    v-for="(menuItem, index) in menuItems"
                                    :key="index"
                                    v-slot="{ active }"
                                >
                                    <router-link
                                        :to="menuItem.url"
                                        class="block px-4 py-2 text-sm text-gray-700"
                                        :class="[active ? 'bg-gray-100' : '']"
                                    >
                                        <span>{{ menuItem.title }}</span>
                                    </router-link>
                                </MenuItem>
                            </template>
                            <MenuItem>
                                <router-link
                                    to="/profile"
                                    class="block px-4 py-2 text-sm text-gray-700 min-w-[155px]"
                                >
                                    <span>Profile</span>
                                </router-link>
                            </MenuItem>
                            <MenuItem>
                                <a
                                    :href="helpLink"
                                    class="block px-4 py-2 text-sm text-gray-700 min-w-[155px]"
                                    target="_blank"
                                >
                                    <span>Help</span>
                                </a>
                            </MenuItem>
                            <MenuItem>
                                <PitchlaneButton
                                    class="block px-4 py-2 text-sm text-gray-700 w-full text-left"
                                    event-name="Log Out"
                                    @click="logout()"
                                >
                                    Log out
                                </PitchlaneButton>
                            </MenuItem>
                        </MenuItems>
                    </transition>
                </Menu>
            </div>
        </div>

        <!-- Actual page contents -->
        <router-view class="flex-grow" />
    </div>
</template>

<style>
.field {
    @apply block w-80 px-3 py-2 rounded-md border focus:outline-none focus:border-blue-500 focus-visible:ring-2 transition;
}

.iub__us-widget {
    display: none !important;
}
</style>
