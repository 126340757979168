<script setup lang="ts">
import { useVModel } from "@vueuse/core"
import {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue"

const props = defineProps({
    modelValue: Boolean,
    persistent: Boolean,
    fullScreen: Boolean,
    width: {
        type: Number,
        default: 420,
    },
})
const open = useVModel(props, "modelValue")

const handleDismiss = () => {
    if (props.persistent) return
    open.value = false
}
</script>

<template>
    <TransitionRoot
        appear
        :show="open"
        as="template"
    >
        <Dialog
            :open="open"
            static
            class="fixed z-50 inset-0 flex justify-center items-end sm:items-center"
            :class="{ 'p-0 sm:p-0': fullScreen, 'p-6 sm:p-12': !fullScreen }"
            @close="handleDismiss"
        >
            <TransitionChild
                as="template"
                enter="transition ease-out duration-150"
                enter-from="opacity-0"
                leave="transition ease-in duration-150"
                leave-to="opacity-0"
            >
                <DialogOverlay class="fixed inset-0 bg-black bg-opacity-50" />
            </TransitionChild>

            <TransitionChild
                as="template"
                enter="transition-all transform duration-150 ease-out"
                enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                leave="transition-all transform duration-150 ease-in"
                leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
                <div
                    class="z-10 max-h-full overflow-y-auto bg-white rounded-md shadow-xl mx-auto w-screen"
                    :style="{
                        maxWidth: fullScreen ? '' : `${width}px`,
                        height: fullScreen ? '100vh' : '',
                    }"
                >
                    <slot />
                </div>
            </TransitionChild>
        </Dialog>
    </TransitionRoot>
</template>
